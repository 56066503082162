import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Review.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Row  } from "react-bootstrap";



function Review() {
  const reviews = [
    {
    content: 'Dr. Rahmath is very friendly and proffessional.\nExplained everything in detail.',
      author: 'Rafnas P P',
     
      rating: 5,
    },
    {
      content: 'Good.. Dr is very humble and friendly.\n',
      author: 'Hasna',
     
      rating: 5,
    },
    {
      content: 'Very reasonable charges.Good experience.\n',
      author: 'Akshay',
     
      rating: 4,
    },

    {
      content: 'Easily accessible location.Doctor is\n very proffessional.',
      author: 'Gayathri',
     
      rating: 5,
    },
    {
      content: 'The clinic is very quiet and neat.\nAnd very professional staff.',
      author: 'Asif',
     
      rating: 5,
    },
    {
      content: 'Very proffessional behaviour from doctor\n and the staffs.Highly recommended.',
      author: 'Nasila',
     
      rating: 5,
    },
  ];

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow prev-arrow" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  };
  
  const NextArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow next-arrow" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  };
  
 
  const getSlidesToShow = () => {
    if (window.innerWidth >= 1400) {
      return 2; 
    } else {
      return 1; 
    }
  };

  const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow());

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(getSlidesToShow());
    };

    window.addEventListener("resize", handleResize);

   
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow, 
    slidesToScroll: 1,
    arrows: true, 
    autoplay: true, 
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    className: "center-slider",
  };

  return (
    <div className="review-section">
    
        <div className="text-center mb-4">
          <Row>
          <h2 className="mainrev">What our patients are saying </h2>
          <p className="rev">Reviews</p>
          </Row>
        </div>

        <Slider  {...settings}>


      
          
          {reviews.map((review, index) => (
           
           <div className="review-item">
                <div  key={index} className="revcol"  xl= {12}  lg = {12} md={12}  >
              <h5 className="revauthor mb-3">{review.author}</h5>
                        <p className="text-muted">
            <i className="quote-icon fas fa-quote-left pe-2" />
            {review.content.split('\n').map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph}
                <br />
              </React.Fragment>
            ))}
          </p>

              <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                {Array.from({ length: review.rating }).map((_, starIndex) => (
                  <li key={starIndex}>
                    <i className="star-icon fas fa-star" />
                  </li>
                ))}
                {Array.from({ length: 5 - review.rating }).map((_, starIndex) => (
                  <li key={starIndex + review.rating}>
                    <i className="star-icon far fa-star" />
                  </li>
                ))}
              </ul>
              </div>
             </div>
            
            
          ))}
          
        </Slider>
      </div>
      
    
  );
}

export default Review;

