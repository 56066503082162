import React, { useState, useEffect } from 'react';
import './Tablesec.css';
import Pagination from "react-js-pagination";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FiPhone,FiMail,FiMessageSquare } from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';

import { getMessages } from '../Api/Auth';

import { ClipLoader } from 'react-spinners';

function Tablesec() {
  
  const [patients, setPatients] = useState([]);
  const [activePage, setActivePage] = useState(1); 
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
      const response = await getMessages();
      const patientsData = response.data; 
      setPatients(patientsData); 
    } catch (error) {
      console.error('Error fetching patients:', error);
    } finally {
      setIsLoading(false); // Set loading to false after the request is completed (whether it succeeds or fails)
    }
  };


    fetchData();
  }, []);



  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
  };

  const [showPopup, setShowPopup] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleRowClick = (patient) => {
    setSelectedPatient(patient);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  
  const patientsPerPage = 8;
  const indexOfLastPatient = activePage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentRows = patients.slice(indexOfFirstPatient, indexOfLastPatient);

  return (
    <div className="tlist">
        
        <div className='ttableheading' >
            MESSAGES
        </div>
      <div className='tmaintable'>

      {isLoading ? (
        <div className="spiner-container">
          <ClipLoader  color="#1bbaaf"  loading={isLoading} size={80} />
          <p className='stext' >
             Loading </p>
        </div>
      ) : (


      


        <table className='ttable patient-container'>
          <thead>
            <tr className='tpatient-row'>
              <th className="tpatient-name">PATIENT NAMES</th>
              <th className="taction-container">ACTIONS</th>
            </tr>
          </thead>

         

          <tbody>

        


            {currentRows.map((patient, index) => (
              <tr className='tpatient-row1' key={index}  >

                <td className='tpatient-namerev'
                 onClick={() => handleRowClick(patient)} >
                  
                  {patient.name}
                
                
                </td>

                <td className='tactionbtn'>

                  <button className="tcall-btn">
                  <a href={`tel:${patient.mobile}`}>

                    <FiPhone className = "iicon" color="white" size={20} />
                    </a>

                     </button>
                     

                  <button className="tmsg-btn">
                  <a href={`mailto:${patient.email}`}>
                    <FiMail  className = "iicon" color="white" size={20} />
                    </a>
                  </button>
                  
                </td>
              </tr>


            ))}
          </tbody>  

            
        </table>

            )}

      </div>

      {showPopup && (
        <div className="tpopup-overlay">
          <div className="tpopup">
            <h2 className='tpophead'>Message Details</h2>
            
            <div className='tfo'>
              <p className='tfp'>
              <FaUser className = "popiicon" color="black" size={20} /> 
              </p>
              <p className='tfnme' >

                Name: </p>
              <p className='tfpp' > {selectedPatient.name}</p>
            </div>


            <div className='tfo'>
              <p className='tfp'>

                 <FiPhone className = "popiicon" color="black" size={20} />
                 </p>
                 <p className='tfnme' >
                Mobile: </p>
              <p className='tfpp' >
              <a href={`tel:${selectedPatient.mobile}`} className='poplink'>{selectedPatient.mobile}</a>
                 </p>
            </div>


            <div className='tfo'>
              <p className='tfp'>
              <FiMail className = "popiicon" color="black" size={20} />
                </p>
                <p className='tfnme' >
                Email: </p>
              <p className='tfpp' > 
              <a href={`mailto:${selectedPatient.email}`} className='poplink'>{selectedPatient.email}</a>
              </p>
            </div>


            <div className='tfo'>
              <p className='tfp'>

              <FiMessageSquare className = "popiicon" color="black" size={20} />
              </p>

              <p className='tfnme' >
                
                Message: </p>
              <p className='tfpp' >
                 {selectedPatient.message}
                 </p>
            </div>

            <div className='clpop' >
            <button className="cpop" onClick={handleClosePopup}>Close</button>

            </div>


          </div>
        </div>
      )}

      <div className="tpagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={patientsPerPage}
          totalItemsCount={patients.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Tablesec;
