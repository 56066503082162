import React from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import Cicon from '../Images/DArt.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FiHome} from 'react-icons/fi';
import { Link } from 'react-router-dom';

import './Tableheader.css';

import { logout } from '../Api/Auth';


import Tablesec from './Tablesec';
import Tabf from './Tabf';


function Tableheader() {

  
  const handleLogout = () => {
    logout();   
  };
 

  return (

   
    <div className='fullt'>
      
      <Navbar expand="xl" bg="light" data-bs-theme="light" className="tab-header" collapseOnSelect>
        <Container fluid>
          <Navbar.Brand>
            <div className="tlogo-container">
              <img src={Cicon} width="55" height="55" className="ad-logo" alt="" />
            </div>
          </Navbar.Brand>

          <div className='tddiv'>

          <Link className = "pff" 
        to= "/admin/dashboard">
          <FiHome className = "headicon"  size={20} />

          </Link>
            
          <Link className="loglink" to="/admin">
              <Button className="custom-dropdown-item" onClick={handleLogout}>
                <FontAwesomeIcon icon={faRightFromBracket} rotation={180} />
              </Button>
            </Link>
                </div>


              
        
          
        </Container>
      </Navbar>

      <Tablesec/>
      <Tabf/>
   
    </div>

   
  );
}

export default Tableheader;
