import React, { useState, useRef, useCallback } from 'react';
import { Button, Alert } from 'react-bootstrap';
import './Otp.css';
import { useNavigate , Link } from 'react-router-dom';

function Otp() {


  const navigate = useNavigate();

  const handleOtpPass = (e) => {
    e.preventDefault();
    navigate('/admin/newpassword'); 
  };


  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [resetMessage, setResetMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const otpInputs = useRef([]);

  const handleOtpChange = useCallback((index, value) => {
    if (value.match(/^[0-9]$/) || value === '') {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Focus on the next input if a digit is entered
      if (value.match(/^[0-9]$/) && index < otpInputs.current.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  }, [otp]);

  const handleContinue = useCallback((e) => {
    e.preventDefault();

    const enteredOtp = otp.join('');
    if (enteredOtp === '') {
      setResetMessage('Please enter your otp.');
      setShowAlert(true);
      return;
    }

    

    setResetMessage('OTP entered successfully!'); 
    setShowAlert(true);

   
    setOtp(new Array(4).fill(''));
  }, [otp]);

  return (
    <div className="main-container" >
      <div className="whit-box">
        <h2 className="otphead">Password Reset</h2>
        <p className='otpsub'>We send a code to abc@gmail.com</p>

        <form onSubmit={handleContinue}>
          <div className="otp-container">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                maxLength="1"
                className="otp-input"
                ref={(el) => (otpInputs.current[index] = el)}
              />
            ))}
          </div>

          <Button onClick = {handleOtpPass} className="resetbtn" type="submit">
            Continue
          </Button>
        </form>

        <p className="sendagain">Didn't receive the otp? Click to resend</p>

        <p className='pback'> 

<Link className = "pbb" to="/admin"> Back to Login page 
 </Link>
 
</p>


      </div>



      {showAlert && (
        <div className='alertotp'>
          <Alert onClose={() => setShowAlert(false)} dismissible>
            {resetMessage}
          </Alert>
        </div>
      )}
    </div>
  );
}

export default React.memo(Otp);
