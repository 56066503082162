import React, { useState, useEffect } from 'react';
import { Nav, Button, Form, FormControl, Navbar, Container, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-scroll";
import { ClipLoader } from 'react-spinners';


import Companyicon from '../Images/DArt.png';

import './Header.css';

import About from './About';
import Company from './Company';
import Appointmentform from './Appointmentform';
import Services from './Services';
import Specialities from './Specialities';
import Straightteeth from './Straightteeth';
import General from './General';
import Review from './Review';
import Map from './Map';
import Contact from './Contact';
import Footer from './Footer';

function Header() {

  const [isLoading, setIsLoading] = useState(true);


  const handlePopupOpen = () => {
    const aboutFormButton = document.getElementById('aboutFormButton');
    if (aboutFormButton) {
      aboutFormButton.click();
    }
  };


  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);



  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1300);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (



    <div>
      {isLoading ? (
        <div className="spinner-container">
          <ClipLoader color="#1bbaaf" loading={isLoading} size={80} />
          <p className='sptext' >
            Loading </p>
        </div>
      ) : (
        <div>





          <Navbar expand="xl" bg="light" href="#logo" data-bs-theme="light" className="header-section" collapseOnSelect>
            <Container fluid>
              <Navbar.Brand>
                <div className="logo-container">

                  <img
                    src={Companyicon}
                    width="75"
                    height="75"
                    className="h-logo"
                    alt=""
                  />
                  <div className="logo-text">
                    <span className="logo-line1">D Art Dentistry</span>
                    <span className="logo-line2">Come smile with us ! </span>
                  </div>
                </div>



              </Navbar.Brand>

              <Navbar.Toggle aria-controls="navbar-collapse-content" />
              <Navbar.Collapse id="navbar-collapse-content">
                {isMobileView ? (
                  <Nav className="me-auto">

                    <Link to="Company" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>About Us</Nav.Link>
                    </Link>

                    <Link to="Services" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>Services</Nav.Link>
                    </Link>
                    <Link to="Whyus" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>Why Us</Nav.Link>
                    </Link>
                    <Link to="whatwedo" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>What we do</Nav.Link>
                    </Link>
                    <Link to="contact" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>Contact Us</Nav.Link>
                    </Link>
                  </Nav>
                ) : (
                  <Nav className="me-auto">

                    <Link to="Company" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>About Us</Nav.Link>
                    </Link>


                    <Link to="Services" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>Services</Nav.Link>
                    </Link>
                    <Link to="Whyus" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>Why Us</Nav.Link>
                    </Link>
                    <Link to="whatwedo" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>What we do</Nav.Link>
                    </Link>
                    <Link to="contact" smooth={true} duration={300} offset={-50}>
                      <Nav.Link className='navothers'>Contact Us</Nav.Link>
                    </Link>


                    <Form className=" fobtn">

                      <Link to="About" smooth={true} duration={100} offset={-50}>
                        <Button className="header btn-appointment" onClick={handlePopupOpen} >
                          Book Appointment
                        </Button>
                      </Link>


                    </Form>

                  </Nav>


                )}




              </Navbar.Collapse>
            </Container>
          </Navbar>


          <About />
          <Company />
          <Services />
          <Specialities />
          <Straightteeth />
          <General />
          <Review />
          <Map />
          <Contact />
          <Footer />
          <Appointmentform />


        </div>
      )}
    </div>
  );
}

export default Header;

