
import React from 'react';
import './Dashf.css';

function Dashf () {

    return (


<div className="ft text-white">
  
    
      
      
          <div className ="fter-text">
            Copyright 2023 ©&nbsp;
            <a href="https://www.dartdentistry.com" rel="noopener noreferrer" className="fter-link">
              D Art Dentistry | &nbsp;
            </a>
            Developed by
            <a href="https://www.weberfox.com" rel="noopener noreferrer" className="fter-link">
              {' '}
              WeberFox Technologies Pvt Ltd.{' '}
            </a>
         

          </div>
      
      
    
  
</div>


    );

}

export default Dashf;