import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';
import { getAppointmentstat } from '../Api/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock, faHistory, faList } from '@fortawesome/free-solid-svg-icons';

import Patienttable from './Patienttable';


function Dashboard() {

 
  const [tableFilter, setTableFilter] = useState('all');


  const [todayAppointments, setTodayAppointments] = useState(0);
  const [upcomingAppointments, setUpcomingAppointments] = useState(0);
  const [previousAppointments, setPreviousAppointments] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState(0);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await getAppointmentstat();

      
        if (response.dataFound) {
          setTodayAppointments(response.countToday);
          setUpcomingAppointments(response.countUpcoming);
          setPreviousAppointments(response.countPrevious);
          setTotalAppointments(response.countAll);
        } else {
          

          setTodayAppointments(0);
          setUpcomingAppointments(0);
          setPreviousAppointments(0);
          setTotalAppointments(0);
        }
      } catch (error) {
        console.error('Error fetching appointment statistics:', error);
      }
    };

    fetchData(); 
  }, []);

 


  const handleColumnClick = (filter) => {
    setTableFilter(filter);
  };



      return (

        <div> 
        <div className="dashboard">
         
         <div className="header-container">
            <h1 className="main-heading"> D ART DASHBOARD</h1>
        
        
          <Container>

            <Row className="maindash">
            <Col className="dashboard-col1 col-md-6 col-lg-3"
            
            onClick={() => handleColumnClick('today')}>
            
                  
                <div className="content-wrapper">
                <div className="tap-container">

                <FontAwesomeIcon icon={faCalendar}className="column-image" />
                 
                 
                    <p className="tapm">Today's Appointments</p>
                    </div>

                 
                
                  <p className="tap">
                  {todayAppointments}
                    </p>
                    </div>
                  
                
              </Col>

             <Col className="dashboard-col2 col-md-6 col-lg-3"
             
             onClick={() => handleColumnClick('upcoming')}>


                <div className="content-wrapper">
                <div className="tap-container">

                <FontAwesomeIcon icon={faClock}className="column-image" />
                 
                 
                    <p className="tapm">Upcoming Appointments</p>
                    </div>

                 
                
                  <p className="tap">
                  {upcomingAppointments}
                    </p>
                    </div>
                  
                
              </Col>


        <Col className="dashboard-col3 col-md-6 col-lg-3"  
   
          onClick={() => handleColumnClick('previous')}>

                <div className="content-wrapper">
                <div className="tap-container">

                <FontAwesomeIcon icon={faHistory}className="column-image" />
                 
                 
                    <p className="tapm">Previous Appointments</p>
                    </div>

                 
                
                  <p className="tap">
                  {previousAppointments}
                    </p>
                    </div>
                  
                
              </Col>




              <Col className="dashboard-col4 col-md-6 col-lg-3"


               onClick={() => handleColumnClick('all')}>

                <div className="content-wrapper">
                <div className="tap-container">

                <FontAwesomeIcon icon={faList}className="column-image" />
                 
                 
                    <p className="tapm">Total Appointments</p>
                    </div>

                 
                
                  <p className="tap">
                  {totalAppointments}
                    </p>
                    </div>
                  
                
              </Col>


            </Row>
          </Container>

         
          
        </div>

        </div>

   <Patienttable tableFilter={tableFilter} />

   </div>

      );
    }

    export default Dashboard;
