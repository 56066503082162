import React, { useState } from 'react';
import { Button,  Col, Form } from 'react-bootstrap';

import './Map.css';

import { sendMessage } from '../Api/Nonauth';

function Map() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleConfirmationChange = () => {
    setConfirmation(!confirmation);
  };

  const validateMobile = (mobileNumber) => {
    return /^\d{10}$/.test(mobileNumber);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const isMobileNumberValid = validateMobile(mobile);

    if (!isMobileNumberValid) {
      setIsMobileValid(false);
      return;
    }

    setIsLoading(true);

    const messageData = {
      name: name,
      email: email,
      mobile: mobile,
      message: message,
    };

    try {
      await sendMessage(messageData);

      setName('');
      setEmail('');
      setMobile('');
      setMessage('');
      setConfirmation(false);
      setIsMobileValid(true);
      setIsSuccess(true);

      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    } catch (error) {
      setIsError(true);

      
      setTimeout(() => {
        setIsError(false);
      }, 5000);

      console.error('Error sending the message:', error);
    } finally {
      setIsLoading(false);
    }
  };


    return (

        <div className="mapmain">

            <div className="mapyo" >


                <h2 className="maphead" > CONNECT WITH US </h2>

                </div>

                <div className="maprow" >

                   



                        <div className="mapcol">
                            <iframe
                                   className="mapframe"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.752917582252!2d76.3424754!3d10.037236599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d5b490a7423%3A0x74b56a20a0fe21c8!2sD%20Art%20Dentistry!5e0!3m2!1sen!2sin!4v1691139370554!5m2!1sen!2sin"
                              
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />

                        </div>



            

                    <Col >


                    <Form className="formmap" onSubmit={onSubmit}>
            <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="fmapform"
                required
            />
            <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="fmapform"
                required
            />

            
             <Form.Control
              type="tel"
              placeholder="Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className={`fmapform ${!isMobileValid ? 'invalid' : ''}`} 
              required
            />

            {!isMobileValid && (

              <p className="eror">Please enter a valid 10-digit mobile number.</p>

            )}


            <Form.Control
                as="textarea"
                rows={3}
                placeholder="Leave Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="message fmapform"
                required
            />
            <Form.Check
                type="checkbox"
                label="I verify that the information I provided is correct."
                checked={confirmation}
                onChange={handleConfirmationChange}
                className="fmapformc"
                required
            />


            <Button className="mapbtn" type="submit" disabled={!confirmation || isLoading}>
              {isLoading ? 'Sending...' : 'Submit'}
            </Button>

            {isSuccess && (
            <p className="success-message">Message sent successfully!</p>
          )}
          {isError && (
            <p className="er-message">Failed to send message. Please try again later.</p>
          )}


          </Form>
         

                    </Col>


                </div>

            </div>
        

    );
}

export default Map;