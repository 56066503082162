
import React from 'react';
import './Tabf.css';

function Tabf () {

    return (


<div className="tt text-white">
  
    
      
      
          <div className ="tter-text">
            Copyright 2023 ©&nbsp;
            <a href="https://www.dartdentistry.com" rel="noopener noreferrer" className="tter-link">
              D Art Dentistry | &nbsp;
            </a>
            Developed by
            <a href="https://www.weberfox.com" rel="noopener noreferrer" className="tter-link">
              {' '}
              WeberFox Technologies Pvt Ltd.{' '}
            </a>
         

          </div>
      
      
    
  
</div>


    );

}

export default Tabf;