import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import './Newpassword.css';
 import {  Link } from 'react-router-dom';

function Newpassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleResetPassword = (e) => {
    e.preventDefault();

    if (newPassword.length < 8) {
      setResetMessage('Minimum 8 charcters required');
      setShowAlert(true);
      return;
    }

    if (!newPassword || !confirmPassword) {
      setResetMessage('Enter both new and confirm passwords.');
      setShowAlert(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setResetMessage('Passwords do not match.');
      setShowAlert(true);
      return;
    }



    // Password reset success, you can perform your actual password reset logic here.

    setResetMessage('Password has been successfully reset.');
    setShowAlert(true);

    setNewPassword('');
    setConfirmPassword('');
  };

  return (
    <div
      className="main-container">
      <div className="whte-box">
        <h2 className="pswhead">Set new password</h2>
        <p className='pswsubb'>Must be at least 8 characters</p>
        <form onSubmit={handleResetPassword}>
          <Form.Control
            type="password"
            placeholder="Set new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-contr"
          />

          <Form.Control
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-contr"
          />

          <Button className="resetbtn" type="submit">
            Reset Password
          </Button>
        </form>

        <p className='pback'>

          <Link className="pbb" to="/admin"> Back to Login page
          </Link>

        </p>

      </div>

      {showAlert && (
        <div className="alertcsw">
          <Alert onClose={() => setShowAlert(false)} dismissible>
            {resetMessage}
          </Alert>
        </div>
      )}
    </div>
  );
}

export default Newpassword;
