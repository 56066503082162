import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './Straightteeth.css';
import { Link } from "react-scroll";


function Straightteeth() {

  
  const handlePopupOpen = () => {
    const aboutFormButton = document.getElementById('aboutFormButton');
    if (aboutFormButton) {
      aboutFormButton.click();
    }
  };
  
 

  return (
    <div className="straight-teeth-section">
      <Container>
        <div className="straight-teeth-content d-sm-flex justify-content-center align-items-center">
          <div className="left-side  text-sm-left mt-3">
            <h2 className=" main-strat font-weight-bold  mb-3">WOULD YOU LIKE STRAIGHT TEETH?</h2>
            <p className='strat'> Adult or Children Orthodontic treatments</p>
          </div>
          <div className="right-side text-center text-sm-right mt-3 mt-sm-0">
          <Link to="About" smooth={true} duration={300} offset={-50}>
          <Button variant="light" className="b text-nowrap" 
          
          onClick={handlePopupOpen}> 
          Get Appointment Now</Button>
          </Link>

          </div>
        </div>
      </Container>
    </div>


  );
}

export default Straightteeth;