
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Specialities.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './Appointmentform.css';
import Pcare from '../Images/procare.png';
import Lstaff from '../Images/lostaff.png';
import Gmed from '../Images/genmed.png';
import All from '../Images/24x7.png';

import { Link } from 'react-scroll';







function Specialities() {


  
  const handlePopupOpen = () => {
    const aboutFormButton = document.getElementById('aboutFormButton');
    if (aboutFormButton) {
      aboutFormButton.click();
    }
  };

  

 

  const specialties = [
    {
      name: 'Proffesional Care',
      image: Pcare ,
      description: 'Our team is qualified and\n experienced to practise all\n aspects of dental care ',
    },
    {
      name: 'Lovely Staff',
      image: Lstaff,
      description: 'To be kind, friendly and\n pleasant to be with is one\n of prime motto',
    },
    {
      name: 'General Medicine',
      image: Gmed,
      description: 'You can save a visit to pharmacy.\n We stock anti-biotics,\n antacides, anti-inflammatory etc.',
    },
    {
      name: '24/7 Appointments',
      image: All,
      description: 'Toothache when you wake up?\n No worries, you can get us on call\n anytime to book an appointment',
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);


  const goToPreviousSpeciality = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? specialties.length - 1 : prevIndex - 1));
  };

  const goToNextSpeciality = () => {
    setCurrentIndex((prevIndex) => (prevIndex === specialties.length - 1 ? 0 : prevIndex + 1));
  };


  return (
    <div id="Whyus" className="specialities-section">
      <Container>
        <h2 className="text-center font-weight-bold">Why Choose Our Practice</h2>
        
        <Row className="specialties-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center d-block d-xxl-none ">
            <div className="specialities-items-mobile">
           
            <FontAwesomeIcon
                icon={faChevronLeft}
                className="arrow-icon left-arrow"
                onClick={goToPreviousSpeciality}
              />
              
              {specialties.slice(currentIndex, currentIndex + 2).map((speciality, index) => (
                <div key={index} className={`specialities-item ${index === 0 ? 'active' : ''}`}>
                  <img
                    src={speciality.image}
                    alt="Speciality Icon"
                    className="speciality-icon"
                    title={speciality.name}
                  />
                  <h3 className="speciality-name">{speciality.name}</h3>
                  <div className="speciality-description">
  {speciality.description.split('\n').map((line, index) => (
    <p key={index}>{line}</p>
  ))}
</div>
                </div>
              ))}
             
              <FontAwesomeIcon
                icon={faChevronRight}
                className="arrow-icon right-arrow"
                onClick={goToNextSpeciality}
              />
              </div>
            
          </Col>

          <Col xxl={12} className="text-center d-none d-xxl-block">
            <div className="specialities-items-desktop">
              {specialties.map((specialty, index) => (
                <div
                  key={index}
                  className={`speciality-item ${index === currentIndex ? 'active' : ''}`}
                >
                  <img
                    src={specialty.image}
                    alt="Speciality Icon"
                    className="speciality-icon"
                    title={specialty.name}
                  />
                  <h3 className="specialities-name">{specialty.name}</h3>

                 <div className="specialities-description">
  {specialty.description.split('\n').map((line, index) => (
    <p key={index}>{line}</p>

   
  ))}
</div>

                </div>
              ))}
            </div>
          </Col>
        </Row>

        <div className="text-center mt-4">
        <Link to="About" smooth={true} duration={300} offset={-50}>
          <Button className="sp-bt"  onClick={handlePopupOpen} >
            Get Appointment Now
          </Button>
          </Link>
         
        </div>
           
       

      </Container>
    </div>
  );
}

export default Specialities;
