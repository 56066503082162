
import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './About.css';
import { FaClock, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Appointmentform from './Appointmentform';
import './Appointmentform.css';

import Maincover from '../Images/mcover.jpeg';
import { submitAppointment } from '../Api/Nonauth';




function About() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedDate, setSelectedDate]  = useState('')
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [showFormContainer, setShowFormContainer] = useState(false);


  const [showHeroSection, setShowHeroSection] = useState(true);

  

  useEffect(() => {
    setShowHeroSection(!showFormContainer);
  }, [showFormContainer]);

  const handleConfirmationChange = () => {
    setConfirmation(!confirmation);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();

    
    const appointmentData = {
      name: name,
      email: email,
      date: selectedDate,
      mobile: mobile,
      message: message,
    };

    try {
      
      await submitAppointment(appointmentData);

      setName('');
      setEmail('');
      setSelectedDate('');
      setMobile('');
      setMessage('');
      setConfirmation(false);

      alert('Appointment submitted successfully!');
    } catch (error) {
      
      alert('Failed to submit appointment. Please try again later.');
      console.error('Error submitting appointment:', error);
    }
  };

  const handlePopupOpen = () => {
    setShowFormContainer(true);
  };

  const handlePopupClose = () => {
    setShowFormContainer(false);
  };



  return (
    <div>
      <div className={`hero-section hero-image img-fluid ${showHeroSection ? 'show' : ''}`}
        style={{ backgroundImage: `url(${Maincover})` }}>
         <Container>
          <Row className="herosectionrow">
            <Col className="herosectioncol" >
              <div className="herosection-text-container ">
                <h1 className='hsmainhd' >
                  For All Round <br /> Healthy Smiles<br />
                </h1>
                <p className='hsp' >Comprehensive general and <br /> cosmetic dentistry</p>



                <Button id="aboutFormButton" className="abtbtn" onClick={handlePopupOpen}>
                  Request an Appointment
                </Button>

               
                <p className='hssp-t'> or call </p>
                
                  <Button className="hssp">
                  <FaPhone className="conticon" />
                    <a href="tel: +91 9496673114" class="con-link"> +91 9496673114</a>
                  </Button>
            


              </div>
            </Col>

            <Col id="About" className="offset-md-2">
              {showFormContainer && (
                <Appointmentform
                  onSubmit={handleSubmit}
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  mobile={mobile}
                  setMobile={setMobile}
                  message={message}
                  setMessage={setMessage}
                  confirmation={confirmation}
                  handleConfirmationChange={handleConfirmationChange}
                  onClose={handlePopupClose}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>



      <div className="cont-section">
        <Container>
          <Row className="c-section">
            <Col xl={3} lg={6} md={6} xs={12}>
              <div className="cont">
                <div className="maincont">
                  <p>
                    <FaClock className="conticon" /> Opening Hours
                  </p>
                  <p className="subcont">Monday-Saturday : 9.30 AM - 7 PM </p>
                  <p className="subcont"> Sundays on prior appointment</p>

                </div>
              </div>
            </Col>

            <Col xl={3} lg={6} md={6} xs={12}>
              <div className="cont">
                <div className="maincont2">
                  <p>
                    <FaMapMarkerAlt className="conticon" /> Our Location
                  </p>
                  <p className="subcont2">ABM Arcade,Near Busy Bee Indoor Park,</p>
                  <p className="subcont2"> Kakkanad, Mundampalam, Ernakulam</p>
                </div>
              </div>
            </Col>



            <Col xl={3} lg={6} md={6} xs={12}>
              <div className="cont">
                <div className="maincont3">
                  <p>
                    <MdEmail className="conticon" /> Email Address:
                  </p>
                  <p className="subcont3">
                    <a href="mailto:chiefdentist@dartdentistry.com" className="cont-link">chiefdentist@dartdentistry.com</a>
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={3} lg={6} md={6} xs={12}>
              <div className="cont">
                <div className="maincont4">
                  <p>
                    <FaPhone className="conticon" /> Phone:
                  </p>
                  <p className="subcont4"><a href="tel:+91 9496673114" className="cont-link" >+91 9496673114</a>

                  </p>
                  <p className="subcont4"> <a href="tel:+91 7012304490" className="cont-link" >+91 7012304490</a>

                  </p>


                </div>
              </div>
            </Col>

          </Row>
        </Container>
      </div>
    </div>
  );
}

export default About;
