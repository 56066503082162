import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaApple, FaAndroid } from 'react-icons/fa';
import './Footer.css';

function Footer() {
  return (
    <div className="f text-white">
      
       

            <p  className="footer-text">
  Copyright 2023 ©&nbsp;
  D Art Dentistry | &nbsp; Developed by
  <a href="https://www.weberfox.com" rel="noopener noreferrer" className="footer-link"> WeberFox Technologies Pvt Ltd. </a>
</p>



            
          


       { /*
          <div className="col-xl-6 text-xl-end d-none d-xl-flex justify-content-end align-items-center">
            <div className="foot button-container">

              <button className="f btn btn-dark me-2">
                <span className="icon-container">
                  <FaApple className="foicon" />
                </span>
                <span className="fotext-container">   Get it on <br />
                  <span className="app-store-text">App Store</span>
                </span>
              </button>


              <button className="footer btn btn-dark">
              <span className="foicon-container">
                  <FaAndroid className="footericon" />
                </span>
                <span className="footertext-container"> Get it on <br />
                <span className="app-store-text">Android</span> 
                </span>
              </button>
              
            </div>
          </div>

               */ }
    </div>
  );
}

export default Footer;
