        import axios from 'axios';

        const BASE_URL =   'https://dartdentistry.com/backend/api/';	

        const api = axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        },
        });


        export const sendMessage = async (messageData) => {
        try {
            const response = await api.post('/messages', messageData);

            
            return response.data; 
        } catch (error) {
            throw error;
        }
        };


        

        export const submitAppointment = async (appointmentData) => {
            try {
    
            const response = await api.post('/appointments', appointmentData)
            return response.data;
            } catch (error) {
            throw error;
            }
        };

       