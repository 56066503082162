
import './App.css';

import React from 'react';
    import { BrowserRouter , Route,Routes  } from 'react-router-dom';


    import Header  from  './Components/Landingpage/Header';

    import Login from './Components//Login/Login';
    import Adminpanel from './Components/Admin/Adminpanel'; 
    import Forgotpassword from './Components/Forgot/Forgotpassword';
    import Otp from './Components/Forgot/Otp';
    import Newpassword from './Components/Forgot/Newpassword';
    
    import Tableheader from './Components/Newtable/Tableheader';

   import PrivateRoutes from './Components/Utils/Private';

   

 



function App() {
  return (
    < BrowserRouter>
      <Routes>

      <Route element={<PrivateRoutes />}>


          <Route element={<Adminpanel />} path="/admin/dashboard/*" />

          <Route   element={<Tableheader/>} path="/admin/messages/*" />

          </Route>


        <Route path="/" element={<Header />} />
        <Route path="/admin" element={<Login />} />
        

       




        <Route path="/admin/forgotpassword/*" 
        element={<Forgotpassword/>} />

        <Route path="/admin/otp/*" element={<Otp/>} /> 
         <Route path="/admin/newpassword/*" element={<Newpassword/>} />
        
        

      </Routes>
    </BrowserRouter>
  );
}

export default App;