import React from 'react';
import { Container, Row, Col , Button } from 'react-bootstrap';
import './Company.css';

 
import companyImage from '../Images/Company.jpg';

function Company() {
  return (
    <div id = "Company"  className="general-section">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} className="text-center">
            <h2 className="gen font-weight-bold"> About Us </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={12} md={7}  className="text-center">
            <img
              src= {companyImage}
              alt="Dental"
              className="img-fluid"
            />
          </Col>
          <Col sm={12} md={5} className="text-left lines-container"> 
            <div className="lines">
              
            A center with complete dental solution, where dentistry is an art for our team. We are determined to inform, guide and treat our patients in the best way with affordability.
                 <ul>
                <li>Emergency Care</li>
                <li>Teeth Whitening</li>
                <li>Cosmetic procedures</li>
              </ul>
              <div className= "genbtn">
              <Button className='notconf'>Read More</Button>
              </div>
              
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Company;