import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';

const BASE_URL =   'https://dartdentistry.com/backend/api/';	


const auth = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

let refreshTokenTimeout;

export const setRefreshTokenTimeout = () => {
  const currentAccessToken = localStorage.getItem('accessToken');
  const accessTokenData = jwtDecode(currentAccessToken);

  const timeToRefresh = accessTokenData.exp * 1000 - Date.now() - 5000;

  if (timeToRefresh > 0) {
    refreshTokenTimeout = setTimeout(async () => {
      try {
        const currentRefreshToken = localStorage.getItem('refreshToken');

        if (currentRefreshToken) {
          const response = await auth.post('/refresh', {
            token: currentRefreshToken,
          });

          if (response.status === 200) {
            const newAccessToken = response.data.access_token;
            const newRefreshToken = response.data.refresh_token;

            localStorage.setItem('accessToken', newAccessToken);
            localStorage.setItem('refreshToken', newRefreshToken);

            const newAccessTokenData = jwtDecode(newAccessToken);
            const newAccessTokenExpiration = newAccessTokenData.exp * 1000;

            setRefreshTokenTimeout();
          } else {
            console.error('Refresh token request failed with status code:', response.status);
          }
        }
      } catch (error) {
        console.error('Failed to refresh token:', error);
      }
    }, timeToRefresh);
  }
};

export const useAuth = () => {
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (accessToken) {
      setRefreshTokenTimeout();

      const refreshTokenData = jwtDecode(refreshToken);
      const refreshTokenExpiration = refreshTokenData.exp * 1000;
      const currentTime = Date.now();

      if (refreshTokenExpiration > currentTime) {
        refreshTokens();
      }
    }
  }, []);
};



const isAccessTokenValid = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    return false;
  }

  const accessTokenData = jwtDecode(accessToken);
  const accessTokenExpiration = accessTokenData.exp * 1000;
  const currentTime = Date.now();

  return accessTokenExpiration > currentTime;
};

const isRefreshTokenValid = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    return false;
  }

  const refreshTokenData = jwtDecode(refreshToken);
  const refreshTokenExpiration = refreshTokenData.exp * 1000;
  const currentTime = Date.now();

  return refreshTokenExpiration > currentTime;
};


let isRefreshing = false;

export const refreshTokens = async () => {
  try {
    if (isRefreshing) {
      await new Promise((resolve) => {
        const checkIsRefreshing = () => {
          if (!isRefreshing) {
            resolve();
          } else {
            setTimeout(checkIsRefreshing, 100);
          }
        };
        checkIsRefreshing();
      });
    }

    const currentRefreshToken = localStorage.getItem('refreshToken');

    if (currentRefreshToken) {
      isRefreshing = true;

      const response = await auth.post('/refresh', {
        token: currentRefreshToken,
      });

      if (response.status === 200) {
        const newAccessToken = response.data.access_token;
        const newRefreshToken = response.data.refresh_token;

        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);

        const newAccessTokenData = jwtDecode(newAccessToken);
        const newAccessTokenExpiration = newAccessTokenData.exp * 1000;
      } else {
        if (response.status === 400 || response.status === 401) {
          logout();
        }
        console.error('Refresh token request failed with status code:', response.status);
      }

      isRefreshing = false;
    }
  } catch (error) {
    console.error('Failed to refresh token:', error);
    isRefreshing = false;
  }
};

export const login = async (email, password) => {
  try {
    if (!isRefreshing) {
      const response = await auth.post('/login', {
        email: email,
        password: password,
      });

      const accessToken = response.data.access_token;
      const refreshToken = response.data.refresh_token;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      const accessTokenData = jwtDecode(accessToken);
      const accessTokenExpiration = accessTokenData.exp * 1000;
      setRefreshTokenTimeout();
    } else {
      throw new Error('Refresh operation is already in progress');
    }
  } catch (error) {
    throw error;
  }
};

export const getMessages = async () => {
  try {
    if (!isAccessTokenValid() && isRefreshTokenValid()) {
      await refreshTokens();
    }

    const accessToken = localStorage.getItem('accessToken');

    const response = await auth.get('/messages', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && (error.response.status === 400 || error.response.status === 401)) {
      logout();
    }
    throw error;
  }
};

export const getAppointments = async () => {
  try {
    if (!isAccessTokenValid() && isRefreshTokenValid()) {
      await refreshTokens();
    }

    const accessToken = localStorage.getItem('accessToken');

    const response = await auth.get('/appointments', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && (error.response.status === 400 || error.response.status === 401)) {
      logout();
    }
    throw error;
  }
};

export const getAppointmentstat = async () => {
  try {
    if (!isAccessTokenValid() && isRefreshTokenValid()) {
      await refreshTokens();
    }

    const accessToken = localStorage.getItem('accessToken');

    const response = await auth.get('/appointments/stats', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && (error.response.status === 400 || error.response.status === 401)) {
      logout();
    }
    throw error;
  }
};



export const logout = async () => {
  try {
    const currentRefreshToken = localStorage.getItem('refreshToken');

    if (currentRefreshToken) {
      const response = await auth.post('/logout', {
        token: currentRefreshToken,
      });

      if (response.status === 200) {

      clearTimeout(refreshTokenTimeout);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      console.log("User has successfully logged out")
      }
    } else {
      console.log('No refresh token found. User will be logged out');
      window.location.href="/admin";
    }
  } catch (error) {
    console.error('Failed to log out:', error);
  }
};