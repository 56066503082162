import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import './Forgotpassword.css';

import { useNavigate , Link } from 'react-router-dom';

function Forgotpassword() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);


  const handleForgotPass = (e) => {
    e.preventDefault();
    
    navigate('/admin/otp');
  };

  const handleResetPassword = (e) => {
    e.preventDefault();

    if (!email) {
      setResetMessage('Please enter your email.');
      setShowAlert(true);
      return;
    }

    
    setResetMessage(
      `Password reset link sent to ${email}. Check your email to reset your password.`
    );
    setShowAlert(true);

    
    setEmail('');
  };

  return (
    <div
      className="main-container"
    >
      <div
        className="white-box"
      >
        <h2 className='pswhead' >Forgot Password ? </h2>
        <p> We will send u instructions</p>
        <form onSubmit={handleResetPassword}>

          
          
          <Form.Control
          
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-contr"
           
          />
          <Button  onClick = {handleForgotPass}  className='resetbtn' type="submit">Reset Password
          </Button>
        </form>
        <p className='pback'> 

<Link className = "pbb" to="/admin"> Back to Login page 
 </Link>
 
</p>



      </div>





      {showAlert && (

        <div className='alertpsw'  >

        <Alert  onClose={() => setShowAlert(false)} dismissible>
          {resetMessage}
        </Alert>

        </div>
      )}


    </div>
  );
}

export default Forgotpassword;
