import React, { useState, useEffect } from 'react';
import { Button, Form  } from 'react-bootstrap';
import './Login.css';
import { Envelope, Lock } from 'react-bootstrap-icons';
import logoimage from '../Images/DArt.png';
import { useNavigate, Link } from 'react-router-dom';
import { login } from '../Api/Auth';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  

  const handleLogin = async (e) => {
    e.preventDefault();
    

    try { 
      await login(email, password);
      navigate('/admin/dashboard');
    } catch (error) {
      setError('Invalid authentication');
    } 
  };
  
  
  useEffect(() => {
    setError('');
  }, [email, password]);

  return (
    <div className="mn-container">
      <div className="logsection">
        <div className="log-container">
          <img src={logoimage} alt="Company Logo" className="log" />
          <form className="log-form" onSubmit={handleLogin}>
            <div className="fm-group">
              <Envelope className="inpt-icon" />
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="fm-control"
                required
              />
              <Lock className="input-icon" />
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="fm-control"
                required
              />
              <Button className="btn log" type="submit" >
                  LOGIN
                
              </Button>
             
             { /* <p className="pfor">
                <Link className="pff" to="/admin/forgotpassword">
                  Forgot your password?
                </Link>
                  </p>    */}

              <div className="fm-group error-container">

              {error && <p className="error-message">{error}</p>}

             </div>
            </div>
          </form>
            
        
        </div>

      </div>
    </div>
  );
}

export default Login;
