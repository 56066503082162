import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Appointmentform.css';
import { submitAppointment } from '../Api/Nonauth';

function Appointmentform({
  onSubmit,
  name,
  setName,
  email,
  setEmail,
  selectedDate,
  setSelectedDate,
  mobile,
  setMobile,
  message,
  setMessage,
  confirmation,
  handleConfirmationChange,
  onClose,
}) {

 


  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const validateMobile = (mobileNumber) => {
    return /^\d{10}$/.test(mobileNumber);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const isMobileNumberValid = validateMobile(mobile);

    if (!isMobileNumberValid) {
      setIsMobileValid(false);
      return;
    }

    setIsLoading(true);

    
    const appointmentData = {
      name: name,
      email: email,
      date: selectedDate,
      mobile: mobile,
      message: message,
    };

    try {
      
      await submitAppointment(appointmentData);

      setName('');
      setEmail('');
      setSelectedDate('');
      setMobile('');
      setMessage('');

        handleConfirmationChange(false);
        setIsMobileValid(true);
        setIsSuccess(true);

        setTimeout(() => {
          setIsSuccess(false);
        }, 5000);
      
    } catch (error) {
      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);

      console.error('Error submitting appointment:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!onSubmit) {
    return null;
  }

  return (
    <div className="form-container">
      <button className="close-button" onClick={onClose}>
        <span>&times;</span>
      </button>
      <h2 className="mainformtext">Request an Appointment</h2>
      <p className="sub-text">We will get back to you as soon as we can</p>

      <Form onSubmit={handleSubmit}>
        <Form.Control
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mb-3"
          required
        />

        <Form.Control
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-3"
          required
        />

        <Form.Control
          type="tel"
          placeholder="Mobile Number"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          className={`mb-3 ${!isMobileValid ? 'invalid' : ''}`}
          required
        />

        {!isMobileValid && (
          <p className="eror">Please enter a valid 10-digit mobile number.</p>
        )}

        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          isClearable
          placeholderText="Select a date"
          dateFormat="yyyy-MM-dd"
          className="dp"
          required
        />

        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Leave Your Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="message mb-3"
          required
        />

        <Form.Check
          type="checkbox"
          label="I verify that the information I provided is correct."
          checked={confirmation}
          onChange={handleConfirmationChange}
          className="mb-3"
          required
        />
        <Button className="topbtn" type="submit" disabled={!confirmation || isLoading}>
          {isLoading ? 'Sending...' : 'Submit'}
        </Button>

        {isSuccess && (
          <p className="success-message">Appointment submitted successfully!</p>
        )}
        {isError && (
          <p className="er-message">
            Failed to submit appointment. Please try again later.
          </p>
        )}
      </Form>
    </div>
  );
}

export default Appointmentform;
