

import React from 'react';
    import 'bootstrap/dist/css/bootstrap.css';
    import { Carousel, Button } from 'react-bootstrap';
    import './Services.css';
    import { Link } from 'react-scroll';
    
    
    import root from '../Images/Rootcanal.jpeg';
    import veneer from '../Images/Veneers.jpeg';
    import filling from '../Images/fillings.jpeg';
    import corr from '../Images/correction.jpeg';
    import align from '../Images/aligners.jpeg';
    import sur from '../Images/surgery.jpeg';
    import cos from '../Images/cosmetic.jpeg';
    import clea from '../Images/clean.jpeg';
    import implant from '../Images/impants.jpeg';
    import emergency from '../Images/emer.jpeg';
    import mguard from '../Images/guard.jpeg';
    import llaser from '../Images/laser.png';

   


    function Services() {
 

      const handlePopupOpen = () => {
        const aboutFormButton = document.getElementById('aboutFormButton');
        if (aboutFormButton) {
          aboutFormButton.click();
        }
      };
      
      
    




      const allServiceItems = [
        { name: 'Teeth Cleaning', image: clea  },
        { name: 'Dental Implants', image: implant },
        { name: 'Emergency Care', image: emergency  },
        { name: 'Laser Dentistry', image: llaser },
        { name: 'Mouth Guards', image: mguard  },
        { name: 'Root Canals', image: root },
        { name: 'Veneers and crowns', image: veneer  },
        { name: 'Dental Fillings', image: filling },
        { name: 'Smile correction', image: corr  },
        { name: 'Clear Aligners', image: align },
        { name: 'Oral Surgery', image: sur },
        { name: 'Cosmetic procedures', image: cos },
        
      ];

      const itemsPerSlide = {
        xl: 4,
        lg: 4, 
        sm: 2, 
        md: 3,

      };

      const renderServiceItems = (numItems) => {
        const slides = [];
        const totalItems = allServiceItems.length;
        let slideIndex = 0;

        for (let i = 0; i < totalItems; i += numItems) {
          const slideItems = allServiceItems.slice(i, i + numItems);
          const slideKey = `slide-${slideIndex}`;

          const slide = (
            <Carousel.Item key={slideKey}>
              <Carousel.Caption>
                <div className="d-flex align-items-center">
                  {slideItems.map((service, index) => (
                    <div className="service-item" key={`${slideKey}-${index}`}>
                      <img src={service.image} alt={service.name} className="service-item-image" />
                      <p className='servname'>{service.name}</p>
                    </div>
                  ))}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );

          slides.push(slide);
          slideIndex++;
        }

        return slides;
      };

      const renderCarousel = (screenSize) => {
        const numItems = itemsPerSlide[screenSize];
        const carouselItems = renderServiceItems(numItems);

        return <Carousel indicators={true} controls={true} >{carouselItems}</Carousel>;
      };

      return (
        <div  id = "Services" className="container">
          <div className="main justify-content-center align-items-center">
            <h2 className="carohead">Discover Our Services</h2>
            <p className="subcarou">Come smile with us !</p>
          </div>
          
          <div className="d-xl-block d-none">{renderCarousel('xl')}</div>
          <div className="d-lg-block d-none  d-xl-none">{renderCarousel('lg')}</div>
          <div className="d-sm-block d-md-none d-lg-none d-xl-none">{renderCarousel('sm')}</div>
          <div className=" d-none d-md-block d-lg-none  d-xl-none">{renderCarousel('md')}</div>

          <div className="ser text-center mt-4">
          <Link to="About" smooth={true} duration={300} offset={-50}>
            <Button className="ser-bt" onClick={handlePopupOpen}>
              Get Appointment Now 
            </Button>
            </Link>
          </div>

            
        </div>
      );
    }

    export default Services;
