import React, { useState, useEffect } from 'react';
import './Patienttable.css';
import Pagination from "react-js-pagination";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FiPhone,FiMail,FiMessageSquare ,FiCalendar} from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';

import { getAppointments } from '../Api/Auth';

import { ClipLoader } from 'react-spinners';

function Patienttable({ tableFilter }) {

  
  

  const [patients, setPatients] = useState([]);
  

  const [isLoading, setIsLoading] = useState(true);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 500);

  useEffect(() => {
    setActivePage(1);
  }, [tableFilter]);



  useEffect(() => {
    const fetchData = async () => {

      setIsLoading(true);

      try {
          
        const response = await getAppointments();
        const patientsData = response.data;

        let filteredPatients = patientsData;

        const currentDate = new Date();
const formattedDate = currentDate.toISOString().split('T')[0]; 

if (tableFilter === 'today') {
  filteredPatients = patientsData.filter(
    (patient) => patient.appointment_date.split('T')[0] === formattedDate
  );

} else if (tableFilter === 'upcoming') {
  filteredPatients = patientsData.filter(
    (patient) => new Date(patient.appointment_date) > currentDate
  );

} else if (tableFilter === 'previous') {
  filteredPatients = patientsData.filter(
    (patient) => {
      const appointmentDate = patient.appointment_date.split('T')[0];
      return appointmentDate < formattedDate; 
    }
  );

  
} else if (tableFilter === 'all') {
  filteredPatients = patientsData;
}

        setPatients(filteredPatients);
      } catch (error) {
        console.error('Error fetching patients:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tableFilter]);

  


  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  



  const [activePage, setActivePage] = useState(1); 

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
  };

  const [showPopup, setShowPopup] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleRowClick = (patient) => {
    setSelectedPatient(patient);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  
  const patientsPerPage = 10;
  const indexOfLastPatient = activePage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentRows = patients.slice(indexOfFirstPatient, indexOfLastPatient);

  return (

    <div className="list">

            <div className='condhead' >
          
          
            {tableFilter === 'all' ? 'TOTAL APPOINTMENTS' :
             tableFilter === 'today' ? "TODAY'S APPOINTMENTS" :
             tableFilter === 'upcoming' ? 'UPCOMING APPOINTMENTS' :
             tableFilter === 'previous' ? 'PREVIOUS APPOINTMENTS' : ''}
          

          </div>


      <div className='maintable'>

      {isLoading ? (
        <div className="spiner-container">
          <ClipLoader  color="#1bbaaf"  loading={isLoading} size={80} />
          <p className='stext' >
             Loading </p>
        </div>
      ) : (


        <table className='table patient-container'>
          <thead>
            <tr className='patient-row'>
              <th className="patient-name">PATIENT NAMES</th>

              {isWideScreen && <th className="appdatecontainer">APPOINTMENT DATE</th>}

              <th className="action-container">ACTIONS</th>
             
            </tr>
          </thead>
          <tbody>
            {currentRows.map((patient, index) => (
              <tr className='patient-row1' key={index}  >

                <td className='patient-namerev'
                 onClick={() => handleRowClick(patient)} >
                  
                  {patient.patient_name}
                
                </td>

                
                {isWideScreen && (
                  <td className='patientappdate'>
                   {patient.appointment_date}
                  </td>
                )}


                <td className='actionbtn'>

                  <button className="call-btn">
                  <a href={`tel:${patient.patient_mobile}`}>

                    <FiPhone className = "iicon" color="white" size={20} />
                    </a>

                     </button>
                     

                  <button className="msg-btn">
                  <a href={`mailto:${patient.patient_email}`}>

                    <FiMail  className = "iicon" color="white" size={20} />
                    </a>
                  </button>
                  
                </td>
              </tr>


            ))}
          </tbody>
        </table>

      )}
        
      </div>
     

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h2 className='pophead'>Patient Details</h2>
            
            <div className='fo'>


              <p className='fp'>
              <FaUser className = "popiicon" color="black" size={20} />
              </p>
               <p className='fnme' >
                 Name: </p>
                 
              <p className='fpp' > {selectedPatient.patient_name}</p>
            </div>


            <div className='fo'>
              <p className='fp'>

                 <FiPhone className = "popiicon" color="black" size={20} /> 
                 </p> 
                 <p className='fnme' >
                Mobile: </p>
              <p className='fpp' >
              <a href={`tel:${selectedPatient.patient_mobile}`} className='poplink'>
                {selectedPatient.patient_mobile}</a>
                 </p>
            </div>


            <div className='fo'>
              <p className='fp'>
              <FiMail className = "popiicon" color="black" size={20} />
              </p>
              <p className='fnme' >
                
                Email: </p>
              <p className='fpp' >

              <a href={`mailto:${selectedPatient.patient_email}`} 

              className='poplink'>
                {selectedPatient.patient_email}</a>
              </p>
            </div>


            <div className='fo'>
              <p className='fp'>

              <FiMessageSquare className = "popiicon" color="black" size={20} />
                </p>

                <p className='fnme' >
                Message: </p>
              <p className='fmp' >
                 {selectedPatient.message}
                 </p>
            </div>


            
            <div className='fo'>
              <p className='fp'>

              <FiCalendar className = "popiicon" color="black" size={20} />
              </p>

              <p className='fnme' >
                
                Appointment Date: </p>
              <p className='fpp' >
                 {selectedPatient.appointment_date}
                 </p>
            </div>

            <div className='clpop' >
            <button className="cpop" onClick={handleClosePopup}>Close</button>

            </div>


          </div>
        </div>
      )}

         
        
      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={patientsPerPage}
          totalItemsCount={patients.length}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
        />
      </div>

      </div>
   
  );
}

export default Patienttable;
