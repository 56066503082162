import React from "react";

  
  import { Container, Row, Col } from 'react-bootstrap';
  import {
    FaEnvelope,  FaFacebook, FaTwitter,  FaInstagram, FaYoutube, FaPhone
    
  } from 'react-icons/fa';
 


  import './Contact.css';

  function Contact() {

   
  
    

    return (
      <Container id="contact" className='contact-section full-width'>
        <Row className="align-items-center justify-content-center section-row">

          <Col md={4} className="text-center">
          
            <div className="new-line1">OUR LOCATION</div>
          
            <div className="line1">ABM Arcade,Near Busy Bee Indoor Park, <br /> 
            Edapally-Pukkattupady Road, <br />
            Mundampalam, Ernakulam, Kerala 682021</div>
          </Col>
          




          <Col md={4} className="text-center">
                
                <div className="new-line3">CONTACT US</div>
        <div className="line3">
          <span>
            <FaPhone className="conticon" /> Phone : 
          </span>
          <a href="tel:+91 9496673114" className="contact-link">   +91 9496673114</a>
        </div>


        <div className="line31">
          <span>
            <FaEnvelope className="logo envelope" /> Email : 
          </span>
          <a href="mailto:chiefdentist@dartdentistry.com" className="contact-link"> chiefdentist@dartdentistry.com</a>
        </div>

          
          </Col>
          
          <Col md={4} className="text-center">
          
          
            <div className="new-line2"> SOCIAL MEDIA
            </div>
            <div className="line2">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
              </a>


              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">

              <FaInstagram className="social-icon" />
              </a>

              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="social-icon" />
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"> 
              
              <FaYoutube className="social-icon" />
              </a>
            </div>
          </Col>
        
        </Row>
      </Container>
    );
  }

  export default Contact;