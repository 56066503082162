import React from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import Cicon from '../Images/DArt.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';
import { FiMessageSquare } from 'react-icons/fi';
import { logout } from '../Api/Auth';


import './Adminpanel.css';


import Dashboard from './Dashboard';
import Dashf from './Dashf';  

function AdminPanel() {

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/admin');
    } catch (error) {
      console.error('Logout failed:', error);
    } 
  };
 

  return (
    <div className='full'>
      
      <Navbar expand="xl" bg="light" data-bs-theme="light" className="admin-header" collapseOnSelect>
        <Container fluid>
          <Navbar.Brand>
            <div className="logo-container">
              <img src={Cicon} width="50" height="50" className="ad-logo" alt="" />
            </div>
          </Navbar.Brand>

          <div className='ddiv'>

            
        <Link className = "pff" 
        to= "/admin/messages">
          <FiMessageSquare className = "headicon"  size={20} />

          </Link>

          
       
              <Button className="custom-dropdown-item" onClick={handleLogout}>
                <FontAwesomeIcon icon={faRightFromBracket} rotation={180} />
              </Button>
            
                </div>

              
              
        
          
        </Container>
      </Navbar>

 
      < Dashboard />
      <Dashf />
    </div>

   
  );
}

export default AdminPanel;
