import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './General.css';

 
import generalImage from '../Images/general.jpg';

function General() {
  return (
    <div id = "whatwedo"  className="general-section">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} className="text-center">
            <h2 className="gen font-weight-bold">General Dentistry</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={12} md={6} className="text-center">
            <img
              src= {generalImage}
              alt="Dental"
              className="img-fluid"
            />
          </Col>
          <Col sm={12} md={6} className="text-left lines-container"> 
            <div className="lines">
              
                 We provide essential Dental Care for your entire family
                 And offer advanced dental services necessary.
                 Our general dentistry services include:
                 <ul>
                <li>Dental Checkups and Cleanings</li>
                <li>Tooth Extractions</li>
                <li>Periodontal Maintenance</li>
              </ul>
              <div className= "genbtn">
              <Button className='notconf'>Read More</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default General;
